export const ENTITY_PATH_NAME = {
  RESUMEN_DE_CUENTA: "resumen-de-cuenta",
  COMPRAS_PENDIENTES: "compras-pendientes",
  COMPRAS_VENCIDAS: "compras-vencidas",
  COMPRAS: "compras",
  PAGOS: "pagos",
};

export const ROUTES = {
  [ENTITY_PATH_NAME.RESUMEN_DE_CUENTA]: "Resumen de Cuenta",
  [ENTITY_PATH_NAME.COMPRAS_PENDIENTES]: "Compras Pendientes",
  [ENTITY_PATH_NAME.COMPRAS_VENCIDAS]: "Compras Vencidas",
  [ENTITY_PATH_NAME.COMPRAS]: "Compras",
  [ENTITY_PATH_NAME.PAGOS]: "Pagos",
};
