import { styled } from "@mui/material/styles";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { Drawer as MuiDrawer, Toolbar, IconButton, Typography, Box } from "@mui/material";
import React, { FunctionComponent } from "react";
import { SideBar } from "../SideBar";
import logoFidelWhite from "./../../../img/logoWhite.png";

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const Appbar: FunctionComponent<{}> = (props) => {
  const drawerWidth: number = 240;

  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <>
      <AppBar position="absolute" open={open}>
        <Toolbar
          disableGutters={true}
          sx={{
            paddingLeft: "10px",
            paddingRight: "20px",
            background: "#6ea877", // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: "10px",
              ...(open && { display: "none" }),
            }}
          >
            <img src={logoFidelWhite} style={{ height: "30px" }} />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              flex: 1,
              justifyContent: "space-between",
            }}
          >
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ fontSize: "22px", ...(open && { paddingLeft: "10px" }) }}
            >
              Portal de Clientes
            </Typography>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{
                fontSize: "15px",
                marginTop: "7px",
                fontWeight: "normal",
              }}
            >
              <a
                style={{ color: "inherit", textDecoration: "none" }}
                href="https://fidel.com.ar/"
                target="_blank"
              >
                fidel.com.ar
              </a>
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
      <SideBar open={open} toggleDrawer={toggleDrawer} />
    </>
  );
};
