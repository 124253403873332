import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Purchases } from "../../api/services/portal-client/portal-client.types";
import {
  ClientService,
  ClientServiceFiles,
} from "../../api/services/portal-client/portal-client.service";
import { useStyles } from "./Tables.style";
import { useSearchParams } from "react-router-dom";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";
import NoData from "../Error/NoData";
import { useEffect, useState } from "react";
import { Box, CircularProgress, IconButton } from "@mui/material";
import { downloadPDF, formatDateString, formatPrice, verifyPDF } from "../../utils/utils";
import { reportType } from "../../enums/enums";

export const PurchasesTable: React.FunctionComponent<{}> = (props) => {
  const [searchParams] = useSearchParams();
  const hash = searchParams.get("hash")!;

  const [purchases, setPurchases] = useState<Purchases[]>();

  useEffect(() => {
    ClientService.getPurchases(hash).then((res) => setPurchases(res));
  }, []);

  const classes = useStyles();

  return (
    <React.Fragment>
      {purchases && purchases.length > 0 ? (
        purchases[0].type !== "portalCliente404notfound" ? (
          <>
            <Table size="medium">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      borderTop: "1px solid #ddd",
                      borderLeft: "1px solid #ddd",
                      backgroundColor: "#ddd",
                      padding: "0",
                    }}
                    align="center"
                  >
                    #
                  </TableCell>
                  <TableCell
                    sx={{
                      borderTop: "1px solid #ddd",
                      borderLeft: "1px solid #fff",
                      backgroundColor: "#ddd",
                      padding: "0",
                    }}
                    align="center"
                  >
                    Fecha
                  </TableCell>
                  <TableCell
                    sx={{
                      borderTop: "1px solid #ddd",
                      borderLeft: "1px solid #fff",
                      backgroundColor: "#ddd",
                      padding: "0",
                    }}
                    align="center"
                  >
                    Número
                  </TableCell>
                  <TableCell
                    sx={{
                      borderTop: "1px solid #ddd",
                      borderLeft: "1px solid #fff",
                      backgroundColor: "#ddd",
                      padding: "0",
                    }}
                    align="center"
                  >
                    Tipo
                  </TableCell>
                  <TableCell
                    sx={{
                      borderTop: "1px solid #ddd",
                      borderLeft: "1px solid #fff",
                      backgroundColor: "#ddd",
                      padding: "0",
                    }}
                    align="center"
                  >
                    Vencimiento
                  </TableCell>
                  <TableCell
                    sx={{
                      borderTop: "1px solid #ddd",
                      borderLeft: "1px solid #fff",
                      backgroundColor: "#ddd",
                      padding: "0",
                    }}
                    align="center"
                  >
                    Cuotas
                  </TableCell>
                  <TableCell
                    sx={{
                      borderTop: "1px solid #ddd",
                      borderLeft: "1px solid #fff",
                      backgroundColor: "#ddd",
                      padding: "0",
                    }}
                    align="center"
                  >
                    Total
                  </TableCell>
                  <TableCell
                    sx={{
                      borderTop: "1px solid #ddd",
                      borderLeft: "1px solid #fff",
                      backgroundColor: "#ddd",
                      padding: "0",
                    }}
                    align="center"
                  >
                    Saldo
                  </TableCell>
                  <TableCell
                    sx={{
                      borderTop: "1px solid #ddd",
                      borderLeft: "1px solid #fff",
                      borderRight: "1px solid #ddd",
                      padding: "5px",
                      backgroundColor: "#ddd",
                    }}
                    align="center"
                  >
                    Opciones
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {purchases?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell
                      sx={{
                        borderLeft: "1px solid #ddd",
                        borderRight: "1px solid #ddd",
                      }}
                      align="center"
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell sx={{ borderRight: "1px solid #ddd" }} align="center">
                      {formatDateString(row.date, true)}
                    </TableCell>
                    <TableCell sx={{ borderRight: "1px solid #ddd" }} align="center">
                      {row.number}
                    </TableCell>
                    <TableCell sx={{ borderRight: "1px solid #ddd" }} align="center">
                      {row.type}
                    </TableCell>
                    <TableCell sx={{ borderRight: "1px solid #ddd" }} align="center">
                      {formatDateString(row.expiredate)}
                    </TableCell>
                    <TableCell sx={{ borderRight: "1px solid #ddd" }} align="center">
                      {row.dues}
                    </TableCell>
                    <TableCell sx={{ borderRight: "1px solid #ddd" }} align="right">
                      <span className={classes.signo}>$</span>
                      {`${formatPrice(row.total)}`}
                    </TableCell>
                    <TableCell sx={{ borderRight: "1px solid #ddd" }} align="right">
                      <span className={classes.signo}>$</span>
                      {`${formatPrice(row.balance)}`}
                    </TableCell>
                    <TableCell
                      className={classes.cellIcon}
                      sx={{ borderRight: "1px solid #ddd", padding: 0 }}
                      align="center"
                    >
                      {/* Verifica que el tipo de dato coincida con alguno de los siguientes antes de mostrar las opciones */}
                      {row.type === reportType.Factura ||
                      row.type === reportType.FacturaA ||
                      row.type === reportType.FacturaB ||
                      row.type === reportType.Presupuesto ||
                      row.type === reportType.Recibo ||
                      row.type === reportType.ReciboPresupuesto ? (
                        <>
                          <IconButton
                            disableRipple={true}
                            sx={{ margin: 0, padding: 0 }}
                            onClick={() => {
                              downloadPDF(row.type, row.id, hash);
                            }}
                          >
                            <DownloadIcon
                              className={classes.optionIcon}
                              sx={{
                                color: "#555",
                                fontSize: 30,
                                marginLeft: 0.5,
                                marginRight: 0.5,
                                marginTop: 0.7,
                              }}
                            />
                          </IconButton>
                          <IconButton
                            disableRipple={true}
                            sx={{ margin: 0, padding: 0 }}
                            onClick={() => {
                              verifyPDF(row.type, row.id, hash);
                              console.log(row.id);
                            }}
                          >
                            <PrintIcon
                              className={classes.optionIcon}
                              sx={{
                                color: "#555",
                                fontSize: 30,
                                marginLeft: 0.5,
                                marginRight: 0.5,
                                marginTop: 0.7,
                              }}
                            />
                          </IconButton>
                        </>
                      ) : (
                        <></>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        ) : (
          <NoData />
        )
      ) : (
        <>
          <Box textAlign={"center"}>
            <CircularProgress size={40} sx={{ margin: "auto", marginTop: "40px" }} />
          </Box>
        </>
      )}
    </React.Fragment>
  );
};

export default PurchasesTable;
