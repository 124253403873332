import { Container, Typography } from "@mui/material";
import React, { FunctionComponent } from "react";
import { useStyles } from "./NoHash.styles";
import logoFidel from "../../../img/Logo_Fidel_a_cargar.png";

const NoHash: FunctionComponent<{ titulo: string; subtitulo: string }> = (params) => {
  const classes = useStyles();

  return (
    <>
      <Container maxWidth={false} className={classes.background}>
        <Typography
          variant="h2"
          component="h2"
          align="center"
          display="block"
          pt={23}
          className={classes.titulo}
        >
          {params.titulo}
        </Typography>

        <Typography
          variant="h5"
          component="h4"
          display="block"
          align="center"
          pt={3}
          className={classes.subtitulo}
        >
          {params.subtitulo}
        </Typography>
        <Typography variant="h5" mt={4} display="block" align="center" className={classes.msg}>
         Click{" "} 
          {
            <a href="https://fidel.com.ar/" className={classes.url}>
              aquí
            </a>
          }
          {" "}para ir al sitio web de Fidel.
        </Typography>
        <div style={{textAlign:"center", marginTop:"5px"}}>
          <a href="https://fidel.com.ar/" className={classes.url}>
            <img src={logoFidel} style={{ height: "100px"}} />
          </a>
        </div>
      </Container>
    </>
  );
};

export default NoHash;
