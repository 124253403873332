import { BrowserRouter } from "react-router-dom";
import { Box, CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { Main } from "./pages/Main";

function App() {
  const mdTheme = createTheme();

  return (
    <BrowserRouter>
      <ThemeProvider theme={mdTheme}>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <Main />
        </Box>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
