import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  icon: {},
  button: {},
  navLink: {
    textDecoration: "none",
    color: "inherit",
    "& $button:hover": {
      backgroundColor: "#cecece",
    },
  },
  navLinkActive: {
    color: "inherit",
    textDecoration: "none",
    "& $icon": {},
    "& $button": {
      backgroundColor: "#bbbbbb",
    },
  },
  logoFidel: {
    height: "48px",
    marginTop: "5px",
  },
});
