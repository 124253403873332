import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  box: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    ["@media only screen and (max-width: 800px)"]: {
      display: "block",
    },
  },
  tableName: {
    fontSize: "16px",
    ["@media only screen and (max-width: 800px)"]: {
      display: "block",
      fontSize: "1.2em",
      wordWrap: "break-word",
    },
  },
  balance: {
    fontSize: "20px",
    ["@media only screen and (max-width: 800px)"]: {
      fontSize: "1em",
      display: "block",
      width: "100%",
      float: "none",
    },
  },
  youtubeButton: {
    backgroundColor: "#CE0000",
    "&:hover": {
      backgroundColor: "#CE0000",
      opacity: "0.7",
    },
    minWidth: 0,
  },
});
