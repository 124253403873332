import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

const TOKEN = "";
const URL = process.env.REACT_APP_API_URL; //en main apunta a dev

export abstract class ApiClient {
  RestClientInstance: AxiosInstance;
  endpoint?: string;

  constructor() {
    this.RestClientInstance = axios.create({
      baseURL: URL,
      headers: {
        Accept: "application/json",
        authorization: TOKEN,
      },
    });
  }

  get(url: string, params: AxiosRequestConfig) {
    return this.RestClientInstance.get(url, params);
  }
}

export abstract class ApiClientFiles {
  RestClientInstance: AxiosInstance;
  endpoint?: string;

  constructor() {
    this.RestClientInstance = axios.create({
      baseURL: URL,
      headers: {
        Accept: "application/json",
        authorization: TOKEN,
      },
      responseType: "blob",
    });
  }

  get(url: string, params: AxiosRequestConfig) {
    return this.RestClientInstance.get(url, params);
  }
}
