import { ClientServiceFiles } from "../api/services/portal-client/portal-client.service";
import { reportType } from "../enums/enums";

export const formatDate = (date: Date, withHours: boolean = false) => {
  var dateFormated = new Date(date);
  return dateFormated.toLocaleString("es-ES", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    ...(withHours && {
      hour: "2-digit",
      minute: "2-digit",
    }),
  });
};

export default formatDate;

export const formatDateString = (date: string, withHours: boolean = false) => {
  var dateFormated = new Date(date);
  return dateFormated.toLocaleString("es-ES", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    ...(withHours && {
      hour: "2-digit",
      minute: "2-digit",
    }),
  });
};

export const verifyPDF = (type: string, id: number, hash: string) => {
  if (type === reportType.Factura || type === reportType.FacturaA) {
    ClientServiceFiles.getPrint(hash, 14, id);
  } else if (type === reportType.Presupuesto || type === reportType.DevolucionPresupuesto) {
    ClientServiceFiles.getPrint(hash, 12, id);
  } else if (type === reportType.Recibo) {
    ClientServiceFiles.getPrint(hash, 24, id);
  } else if (type === reportType.ReciboPresupuesto) {
    ClientServiceFiles.getPrint(hash, 25, id);
  } else if (type === reportType.FacturaB) {
    ClientServiceFiles.getPrint(hash, 15, id);
  }
};

export const downloadPDF = (type: string, id: number, hash: string) => {
  if (type === reportType.Factura || type === reportType.FacturaA) {
    ClientServiceFiles.getDownloadPDF(hash, 14, id);
  } else if (type === reportType.Presupuesto || type === reportType.DevolucionPresupuesto) {
    ClientServiceFiles.getDownloadPDF(hash, 12, id);
  } else if (type === reportType.Recibo) {
    ClientServiceFiles.getDownloadPDF(hash, 24, id);
  } else if (type === reportType.ReciboPresupuesto) {
    ClientServiceFiles.getDownloadPDF(hash, 25, id);
  } else if (type === reportType.FacturaB) {
    ClientServiceFiles.getDownloadPDF(hash, 15, id);
  }
};

export const formatPrice = (price: number) =>
  price.toLocaleString("en-DE", {
    minimumFractionDigits: 2,
  });
