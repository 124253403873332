import { ApiClient, ApiClientFiles } from "../../api";
import { AccountSummary, CustomerInfo, Payments, Purchases } from "./portal-client.types";

export const ClientService = new (class Service extends ApiClient {
  endpoint = "";

  getCustomerInfo(hash: string): Promise<CustomerInfo> {
    return this.get(`customer-portal/${hash}/info`, {
      params: {},
    })
      .then((res) => res.data)
      .catch((error) => console.log(error));
  }

  getAccountSummary(hash: string): Promise<AccountSummary[]> {
    return this.get(`customer-portal/${hash}/account-summary`, {
      params: {},
    })
      .then((res) => res.data)
      .catch((error) => console.log(error));
  }

  getPendingPurchases(hash: string): Promise<Purchases[]> {
    return this.get(`customer-portal/${hash}/purchases`, {
      params: { pending: true, expired: false },
    })
      .then((res) => res.data)
      .catch((error) => console.log(error));
  }

  getExpiredPurchases(hash: string): Promise<Purchases[]> {
    return this.get(`customer-portal/${hash}/purchases`, {
      params: { pending: true, expired: true },
    })
      .then((res) => res.data)
      .catch((error) => console.log(error));
  }

  getPurchases(hash: string): Promise<Purchases[]> {
    return this.get(`customer-portal/${hash}/purchases`, {
      params: { pending: false, expired: false },
    })
      .then((res) => res.data)
      .catch((error) => console.log(error));
  }

  getPayments(hash: string): Promise<Payments[]> {
    return this.get(`customer-portal/${hash}/payments`, {
      params: {},
    })
      .then((res) => res.data)
      .catch((error) => console.log(error));
  }
})();

//PDF Reports
export const ClientServiceFiles = new (class Service extends ApiClientFiles {
  endpoint = "";

  getPrint(hash: string, PDFType: number, id: number): Promise<void> {
    return this.get(`customer-portal/${hash}/report`, {
      params: { PDFType, id },
    }).then((res) => {
      const file = new Blob([res.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    });
  }

  getDownloadPDF(hash: string, PDFType: number, id: number): Promise<void> {
    return this.get(`customer-portal/${hash}/report`, {
      params: { PDFType, id },
    }).then((res) => {
      const file = new Blob([res.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      var tempLink = document.createElement("a");
      tempLink.href = fileURL;
      tempLink.setAttribute("download", "Reporte.pdf");
      tempLink.click();
    });
  }
})();
