import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { FunctionComponent, useEffect } from "react";
import { AccountSummary } from "../../api/services/portal-client/portal-client.types";
import {
  ClientService,
  ClientServiceFiles,
} from "../../api/services/portal-client/portal-client.service";
import { useStyles } from "./Tables.style";
import { useSearchParams } from "react-router-dom";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";
import NoData from "../Error/NoData";
import { Box, CircularProgress, IconButton } from "@mui/material";
import { downloadPDF, formatDateString, formatPrice, verifyPDF } from "../../utils/utils";
import { reportType } from "../../enums/enums";

export const AccountSummaryTable: FunctionComponent<{}> = (props) => {
  const [searchParams] = useSearchParams();
  const hash = searchParams.get("hash")!;

  const [accountSummary, setAccountSummary] = React.useState<AccountSummary[]>();

  useEffect(() => {
    ClientService.getAccountSummary(hash).then((res) => setAccountSummary(res));
  }, []);

  const classes = useStyles();

  return (
    <React.Fragment>
      {accountSummary && accountSummary.length > 0 ? (
        accountSummary[0].description !== "portalCliente404notfound" ? (
          <>
            <Table size="medium">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      borderTop: "1px solid #ddd",
                      borderLeft: "1px solid #ddd",
                      padding: "0",
                      backgroundColor: "#ddd",
                    }}
                    align="center"
                  >
                    #
                  </TableCell>
                  <TableCell
                    sx={{
                      borderTop: "1px solid #ddd",
                      borderLeft: "1px solid #fff",
                      padding: "0",
                      backgroundColor: "#ddd",
                    }}
                    align="center"
                  >
                    Fecha
                  </TableCell>
                  <TableCell
                    sx={{
                      borderTop: "1px solid #ddd",
                      borderLeft: "1px solid #fff",
                      padding: "0",
                      backgroundColor: "#ddd",
                    }}
                    align="center"
                  >
                    Número
                  </TableCell>
                  <TableCell
                    sx={{
                      borderTop: "1px solid #ddd",
                      borderLeft: "1px solid #fff",
                      padding: "0",
                      backgroundColor: "#ddd",
                    }}
                    align="center"
                  >
                    Descripción
                  </TableCell>
                  <TableCell
                    sx={{
                      borderTop: "1px solid #ddd",
                      borderLeft: "1px solid #fff",
                      padding: "0",
                      backgroundColor: "#ddd",
                    }}
                    align="center"
                  >
                    Debe
                  </TableCell>
                  <TableCell
                    sx={{
                      borderTop: "1px solid #ddd",
                      borderLeft: "1px solid #fff",
                      padding: "0",
                      backgroundColor: "#ddd",
                    }}
                    align="center"
                  >
                    Haber
                  </TableCell>
                  <TableCell
                    sx={{
                      borderTop: "1px solid #ddd",
                      borderLeft: "1px solid #fff",
                      padding: "0",
                      backgroundColor: "#ddd",
                    }}
                    align="center"
                  >
                    Total
                  </TableCell>
                  <TableCell
                    sx={{
                      borderTop: "1px solid #ddd",
                      borderRight: "1px solid #ddd",
                      borderLeft: "1px solid #fff",
                      padding: "5px",
                      backgroundColor: "#ddd",
                    }}
                    align="center"
                  >
                    Opciones
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {accountSummary?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell
                      sx={{
                        borderLeft: "1px solid #ddd",
                        borderRight: "1px solid #ddd",
                      }}
                      align="center"
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell sx={{ borderRight: "1px solid #ddd" }} align="center">
                      {formatDateString(row.date, true)}
                    </TableCell>
                    <TableCell sx={{ borderRight: "1px solid #ddd" }} align="center">
                      {row.number}
                    </TableCell>
                    <TableCell sx={{ borderRight: "1px solid #ddd" }} align="center">
                      {row.description}
                    </TableCell>
                    <TableCell sx={{ borderRight: "1px solid #ddd" }} align="right">
                      <span className={classes.signo}>$</span>
                      {`${formatPrice(row.debt)}`}
                    </TableCell>
                    <TableCell sx={{ borderRight: "1px solid #ddd" }} align="right">
                      <span className={classes.signo}>$</span>
                      {`${formatPrice(row.credit)}`}
                    </TableCell>
                    <TableCell sx={{ borderRight: "1px solid #ddd" }} align="right">
                      <span className={classes.signo}>$</span>
                      {`${formatPrice(row.total)}`}
                    </TableCell>
                    <TableCell
                      className={classes.cellIcon}
                      sx={{ borderRight: "1px solid #ddd", padding: 0 }}
                      align="center"
                    >
                      {/* Verifica que el tipo de dato coincida con alguno de los siguientes antes de mostrar las opciones */}
                      {row.description === reportType.Factura ||
                      row.description === reportType.FacturaA ||
                      row.description === reportType.FacturaB ||
                      row.description === reportType.Presupuesto ||
                      row.description === reportType.Recibo ||
                      row.description === reportType.ReciboPresupuesto ||
                      row.description === reportType.DevolucionPresupuesto ? (
                        <>
                          <IconButton
                            disableRipple={true}
                            sx={{ margin: 0, padding: 0 }}
                            onClick={() => {
                              downloadPDF(row.description, row.id, hash);
                            }}
                          >
                            <DownloadIcon
                              className={classes.optionIcon}
                              sx={{
                                color: "#555",
                                fontSize: 30,
                                marginLeft: 0.5,
                                marginRight: 0.5,
                                marginTop: 0.7,
                              }}
                            />
                          </IconButton>
                          <IconButton
                            disableRipple={true}
                            sx={{ margin: 0, padding: 0 }}
                            onClick={() => {
                              verifyPDF(row.description, row.id, hash);
                            }}
                          >
                            <PrintIcon
                              className={classes.optionIcon}
                              sx={{
                                color: "#555",
                                fontSize: 30,
                                marginLeft: 0.5,
                                marginRight: 0.5,
                                marginTop: 0.7,
                              }}
                            />
                          </IconButton>
                        </>
                      ) : (
                        <></>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        ) : (
          <NoData />
        )
      ) : (
        <>
          <Box textAlign={"center"}>
            <CircularProgress size={40} sx={{ margin: "auto", marginTop: "40px" }} />
          </Box>
        </>
      )}
    </React.Fragment>
  );
};

export default AccountSummaryTable;
