import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  customerResponsive: {
    display: "none",
  },
  signo: {
    float: "left",
  },

  optionIcon: {
    borderRadius: "50%",
    padding: "5px",
  },
  cellIcon: {
    "& $optionIcon:hover": {
      backgroundColor: "#6ea877",
      color: "#fff",
    },
  },
});
