import React, { FunctionComponent } from "react";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { Box, Button, Divider, Icon, Typography } from "@mui/material";
import { useStyles } from "./header.style";
import { ROUTES } from "../../utilities/format";
import { formatPrice } from "../../../utils/utils";
import YouTubeIcon from "@mui/icons-material/YouTube";

export const Header: FunctionComponent<{
  accountName: string;
  balance: number;
}> = (props) => {
  const path = window.location.pathname;
  const tableName = path.slice(1);

  const classes = useStyles();

  return (
    <div>
      <Box
        sx={{
          p: 1,
          paddingBottom: 0,
        }}
      >
        <Box>
          <Box className={classes.box}>
            <Typography paddingBottom={1} fontSize={22} display="block" sx={{ fontWeight: "bold" }}>
              {props.accountName.toUpperCase()}
            </Typography>
            <Button
              variant="contained"
              href="https://www.youtube.com/watch?v=Rwkn7HQ5o9s"
              target={"_blank"}
              disableElevation
              size="small"
              disableRipple={true}
              className={classes.youtubeButton}
              title="Video Explicativo"
            >
              <YouTubeIcon />
            </Button>
          </Box>
          <Box className={classes.box}>
            <Typography className={classes.tableName}>{ROUTES[tableName]}</Typography>
            <Typography className={classes.balance}>
              {props.balance >= 0
                ? "Saldo: $ " + formatPrice(props.balance)
                : "Saldo: -$ " + formatPrice(props.balance * -1)}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Divider sx={{ marginBottom: 2 }} />
    </div>
  );
};
