import { Container, Typography } from "@mui/material";

const NoData = () => {
  return (
    <>
      <Container>
        <Typography align="center">No se han encontrado resultados</Typography>
      </Container>
    </>
  );
};

export default NoData;
