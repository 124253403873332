import "@fontsource/roboto/700.css";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  titulo: {
    fontWeight: "300",
    wordWrap: "break-word",
    ["@media only screen and (max-width: 800px)"]: {
      fontSize: "2.2em",
    },
  },
  subtitulo: {
    display: "block",
    width: "100%",
    margin: "auto",
    fontSize: "2em",
    ["@media only screen and (max-width: 800px)"]: {
      fontSize: "1.3em",
    },
  },

  msg: {
    width: "100%",
    wordWrap: "break-word",
    "& $a:visited": {
      color: "#6ea877",
    },
    "& $url:hover": {
      textDecoration: "underline",
    },
    ["@media only screen and (max-width: 800px)"]: {
      fontSize: "1em",
    },
  },

  url: {
    textDecoration: "none",
    color: "#6ea877",
  },

  background: {
    width: "100%",
    height: "100vh",
  },
});
