import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { FunctionComponent } from "react";
import { Payments } from "../../api/services/portal-client/portal-client.types";
import {
  ClientService,
  ClientServiceFiles,
} from "../../api/services/portal-client/portal-client.service";
import { useStyles } from "./Tables.style";
import { useSearchParams } from "react-router-dom";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";
import NoData from "../Error/NoData";
import { Box, CircularProgress, IconButton } from "@mui/material";
import { formatDate, formatPrice } from "./../../utils/utils";

export const PaymentsTable: FunctionComponent<{}> = (props) => {
  const [searchParams] = useSearchParams();
  const hash = searchParams.get("hash")!;

  const [payments, setPayments] = React.useState<Payments[]>();

  React.useEffect(() => {
    ClientService.getPayments(hash).then((res) => setPayments(res));
  }, []);

  const classes = useStyles();

  const verificatePDF = (type: number, id: number) => {
    // Type 1 -> Pago en Blanco -> ReporteEnum.Recibo(24)
    // Type 2 -> Pago Presupuesto -> ReporteEnum.ReciboPresupuesto(25)
    ClientServiceFiles.getPrint(hash, 23 + type, id);
  };

  const downloadPDF = (type: number, id: number) => {
    ClientServiceFiles.getDownloadPDF(hash, 23 + type, id);
  };

  return (
    <React.Fragment>
      {payments && payments.length > 0 ? (
        payments[0].id !== 0 ? (
          <>
            <Table size="medium">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      borderTop: "1px solid #ddd",
                      borderLeft: "1px solid #ddd",
                      backgroundColor: "#ddd",
                      padding: "0",
                    }}
                    align="center"
                  >
                    #
                  </TableCell>
                  <TableCell
                    sx={{
                      borderTop: "1px solid #ddd",
                      borderLeft: "1px solid #fff",
                      backgroundColor: "#ddd",
                      padding: "0",
                    }}
                    align="center"
                  >
                    Fecha
                  </TableCell>
                  <TableCell
                    sx={{
                      borderTop: "1px solid #ddd",
                      borderLeft: "1px solid #fff",
                      backgroundColor: "#ddd",
                      padding: "0",
                    }}
                    align="center"
                  >
                    Número
                  </TableCell>
                  <TableCell
                    sx={{
                      borderTop: "1px solid #ddd",
                      borderLeft: "1px solid #fff",
                      backgroundColor: "#ddd",
                      padding: "0",
                    }}
                    align="center"
                  >
                    Monto
                  </TableCell>
                  <TableCell
                    sx={{
                      borderTop: "1px solid #ddd",
                      borderLeft: "1px solid #fff",
                      borderRight: "1px solid #ddd",
                      padding: "5px",
                      backgroundColor: "#ddd",
                    }}
                    align="center"
                  >
                    Opciones
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {payments?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell
                      sx={{
                        borderLeft: "1px solid #ddd",
                        borderRight: "1px solid #ddd",
                      }}
                      align="center"
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell sx={{ borderRight: "1px solid #ddd" }} align="center">
                      {formatDate(row.date, true)}
                    </TableCell>
                    <TableCell sx={{ borderRight: "1px solid #ddd" }} align="center">
                      {row.number}
                    </TableCell>
                    <TableCell sx={{ borderRight: "1px solid #ddd" }} align="right">
                      <span className={classes.signo}>$</span>
                      {`${formatPrice(row.amount)}`}
                    </TableCell>
                    <TableCell
                      className={classes.cellIcon}
                      sx={{ borderRight: "1px solid #ddd", padding: 0 }}
                      align="center"
                    >
                      <IconButton
                        disableRipple={true}
                        sx={{ margin: 0, padding: 0 }}
                        onClick={() => {
                          downloadPDF(row.paymentType, row.id);
                        }}
                      >
                        <DownloadIcon
                          className={classes.optionIcon}
                          sx={{
                            color: "#555",
                            fontSize: 30,
                            marginLeft: 0.5,
                            marginRight: 0.5,
                            marginTop: 0.7,
                          }}
                        />
                      </IconButton>
                      <IconButton
                        disableRipple={true}
                        sx={{ margin: 0, padding: 0 }}
                        onClick={() => {
                          verificatePDF(row.paymentType, row.id);
                          console.log(row.id);
                        }}
                      >
                        <PrintIcon
                          className={classes.optionIcon}
                          sx={{
                            color: "#555",
                            fontSize: 30,
                            marginLeft: 0.5,
                            marginRight: 0.5,
                            marginTop: 0.7,
                          }}
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        ) : (
          <NoData />
        )
      ) : (
        <>
          <Box textAlign={"center"}>
            <CircularProgress size={40} sx={{ margin: "auto", marginTop: "40px" }} />
          </Box>
        </>
      )}
    </React.Fragment>
  );
};

export default PaymentsTable;
