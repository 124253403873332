import React, { FunctionComponent } from "react";
import {
  ListItemText,
  ListItemButton,
  Drawer as MuiDrawer,
  IconButton,
  Toolbar,
  Divider,
  List,
  ListItemIcon,
  styled,
  Link,
} from "@mui/material";
import {
  MenuBook,
  Payments,
  PendingActions,
  RunningWithErrors,
  ShoppingCart,
} from "@mui/icons-material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import InfoIcon from "@mui/icons-material/Info";
import { NavLink, useSearchParams } from "react-router-dom";
import { useStyles } from "./Sidebar.style";
import logoFidel from "./../../../img/fidel-gris.png";

const drawerWidth: number = 240;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(6.9),
      },
    }),
  },
}));

export const SideBar: FunctionComponent<{
  open: boolean;
  toggleDrawer: () => void;
}> = ({ open, toggleDrawer }) => {
  const [searchParams] = useSearchParams();
  const hash = searchParams.get("hash");

  const classes = useStyles();

  return (
    <Drawer variant="permanent" open={open}>
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          px: [1],
        }}
      >
        <a href="https://fidel.com.ar/" target="_blank">
          <img src={logoFidel} className={classes.logoFidel} />
        </a>

        <IconButton onClick={toggleDrawer}>
          <span>
            <ChevronLeftIcon />
          </span>
        </IconButton>
      </Toolbar>
      <Divider />
      <List component="nav">
        <NavLink
          to={`/resumen-de-cuenta?hash=${hash}`}
          className={(navData) => (navData.isActive ? classes.navLinkActive : classes.navLink)}
        >
          <ListItemButton className={classes.button}>
            <ListItemIcon className={classes.icon} sx={{ minWidth: "38px" }}>
              <MenuBook />
            </ListItemIcon>
            <ListItemText primary="Resumen de Cuenta" />
          </ListItemButton>
        </NavLink>
        <NavLink
          to={`/compras?hash=${hash}`}
          className={(navData) => (navData.isActive ? classes.navLinkActive : classes.navLink)}
        >
          <ListItemButton className={classes.button}>
            <ListItemIcon className={classes.icon} sx={{ minWidth: "38px" }}>
              <ShoppingCart />
            </ListItemIcon>
            <ListItemText primary="Compras" />
          </ListItemButton>
        </NavLink>
        <NavLink
          to={`/compras-pendientes?hash=${hash}`}
          className={(navData) => (navData.isActive ? classes.navLinkActive : classes.navLink)}
        >
          <ListItemButton className={classes.button}>
            <ListItemIcon className={classes.icon} sx={{ minWidth: "38px" }}>
              <PendingActions />
            </ListItemIcon>
            <ListItemText primary="Compras Pendientes" />
          </ListItemButton>
        </NavLink>
        <NavLink
          to={`/compras-vencidas?hash=${hash}`}
          className={(navData) => (navData.isActive ? classes.navLinkActive : classes.navLink)}
        >
          <ListItemButton className={classes.button}>
            <ListItemIcon className={classes.icon} sx={{ minWidth: "38px" }}>
              <RunningWithErrors />
            </ListItemIcon>
            <ListItemText primary="Compras Vencidas" />
          </ListItemButton>
        </NavLink>
        <NavLink
          to={`/pagos?hash=${hash}`}
          className={(navData) => (navData.isActive ? classes.navLinkActive : classes.navLink)}
        >
          <ListItemButton className={classes.button}>
            <ListItemIcon className={classes.icon} sx={{ minWidth: "38px" }}>
              <Payments />
            </ListItemIcon>
            <ListItemText primary="Pagos" />
          </ListItemButton>
        </NavLink>
        <Link
          href="https://drive.google.com/file/d/1gvPicXZoDUJGrGLkWFNUJiekelQIUQh8/view?usp=sharing"
          underline="none"
          color="inherit"
          target="_BLANK"
        >
          <ListItemButton className={classes.button}>
            <ListItemIcon className={classes.icon} sx={{ minWidth: "38px" }}>
              <InfoIcon />
            </ListItemIcon>
            <ListItemText primary="Ayuda" />
          </ListItemButton>
        </Link>
      </List>
    </Drawer>
  );
};
