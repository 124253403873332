import { Box, CircularProgress, Container, Grid, Toolbar } from "@mui/material";
import { Navigate, Route, Routes, useSearchParams } from "react-router-dom";
import AccountSummaryTable from "./Tables/AccountSummary";
import ExpiredPurchasesTable from "./Tables/ExpiredPurchases";
import PaymentsTable from "./Tables/Payments";
import PendingPurchasesTable from "./Tables/PendingPurchases";
import PurchasesTable from "./Tables/Purchases";
import { Appbar } from "../components/layout/AppBar";
import NoHash from "./Error/NoHash/NoHash";
import CustomerInfo from "../api/services/portal-client/portal-client.types";
import React from "react";
import { ClientService } from "../api/services/portal-client/portal-client.service";
import { Header } from "../components/layout/Header";

export function Main() {
  const [searchParams] = useSearchParams();
  const hash = searchParams.get("hash")!;
  const [customer, setCustomer] = React.useState<CustomerInfo>();
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    ClientService.getCustomerInfo(hash).then((res) => {
      if (res) {
        setCustomer(res);
      }
      setIsLoading(false);
    });
  }, []);

  return (
    <>
      {!isLoading ? (
        customer && customer.accountName !== "portalCliente404notfound" && customer.accountName !== "PortalDesactivado" ? (
          <>
            <Appbar />
            <Box
              component="main"
              sx={{
                backgroundColor: "#fff",
                flexGrow: 1,
                minHeight: "100vh",
                overflow: "auto",
              }}
            >
              <Toolbar /> {/*paddingTop del navbar*/}
              <Container sx={{ mt: 2, mb: 4, ml: 0, mr: 0, maxWidth: "none !important" }}>
                <Header accountName={customer?.accountName!} balance={customer?.balance!} />
                <Grid>
                  <Routes>
                    <Route path="/resumen-de-cuenta" element={<AccountSummaryTable />} />
                    <Route path="/compras-pendientes" element={<PendingPurchasesTable />} />
                    <Route path="/compras-vencidas" element={<ExpiredPurchasesTable />} />
                    <Route path="/compras" element={<PurchasesTable />} />
                    <Route path="/pagos" element={<PaymentsTable />} />
                    <Route path="*" element={<Navigate to="/resumen-de-cuenta" />} />
                  </Routes>
                </Grid>
              </Container>
            </Box>
          </>
        ) : (
          customer && customer.accountName === "PortalDesactivado" ? 
            (
            <>
            <NoHash titulo="El acceso al Portal de Clientes está desactivado." subtitulo="" />
            </>
            ) : (
              <>
              <NoHash titulo="ERROR 404" subtitulo="El hash utilizado no es válido" />
              </>
            )
        )
        
      ) : (
        <>
          <CircularProgress size={80} sx={{ margin: "auto", marginTop: "40px" }} />
        </>
      )}
    </>
  );
}
